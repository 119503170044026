<template>
  <div class="mycourse">
    <BannerMycourse></BannerMycourse>
    <div class="mycourse__warp">
      <h2>我的课程</h2>
      <!-- 未登录 -->
      <div class="mycourse__nologin" v-if="$store.state.token === ''">
        <img src="@/assets/images/error/nologin.png" alt="" />
        <button @click="showLogin">登录</button>
        <span>登录后可以听课哦~</span>
      </div>
      <template v-else>
        <!-- 未购买 -->
        <div class="mycourse__nobuied" v-if="mycourseList.length === 0">
          <img src="@/assets/images/error/nobuied.png" alt="" />
          <span>你还没有课程哦~</span>
        </div>
        <!-- 已购课程列表 -->
        <div class="main-video" v-else>
          <div class="video-container">
            <div v-if="dialogVisibleBofang" class="video-wrap">
              <div class="player" id="player" ref="player"></div>
            </div>
            <div v-else>
              <div class="video-wrap no-video">请在视频列表选择视频</div>
            </div>
          </div>
          <div class="video-list">
            <div class="title">视频列表</div>
            <div
              class="mycourse__content"
              v-for="(item, index) in mycourseList"
              :key="index"
            >
              <div class="course-name">
                <img :src="item.mainImage" class="user-img" mode="widthFix" />
                <div class="name">
                  <span class="username">{{ item.name }}</span>
                  <!-- <span class="username">共{{ item.total }}章</span> -->
                </div>
                <button class="btn" @click="getModuleParentRecord(item)">
                  去学习
                </button>
              </div>
              <template v-if="item.goodsId === currentCourse.goodsId">
                <div
                  class="course-module"
                  :key="index"
                  v-for="(moduleItem, index) in moduleList"
                >
                  <div class="course-item">
                    <div class="module-name">{{ moduleItem.chapterName }}</div>
                    <button class="btn" @click="getCharpterRecord(moduleItem)">
                      去学习
                    </button>
                  </div>
                  <template
                    v-if="currentModule.chapterId === moduleItem.chapterId"
                  >
                    <div
                      class="chapter-list"
                      :key="index"
                      v-for="(chapter, index) in chapterList"
                    >
                      <div class="chapter-item">
                        <div class="chapter-name">
                          {{ chapter.chapterName }}
                        </div>
                        <button class="btn" @click="getCharpters(chapter)">
                          去学习
                        </button>
                      </div>
                      <template
                        v-if="currentChapter.chapterId === chapter.chapterId"
                      >
                        <div :key="idx" v-for="(item, idx) in list">
                          <div class="item" v-if="item.isRel === 3">
                            <div class="name">{{ item.chapterName }}</div>
                            <button
                              class="btn"
                              @click="findCharpterVideo(item)"
                            >
                              看视频
                            </button>
                          </div>
                          <!-- <div class="item" v-else-if="[1, 2].includes(item.isRel)">
                      <div class="name">{{ item.chapterName }}</div>
                      <button class="btn">去学习</button>
                    </div> -->
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
    <aside>
      <div v-if="isShowTip" class="toast">
        <div class="toast__con">
          当前章节没有视频
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import BannerMycourse from '@/components/banner/BannerMycourse.vue'
import request from '@/utils/request.js'
export default {
  name: 'Mycourse',
  data() {
    return {
      userInfo: '',
      mycourseList: [], //课程列表list
      moduleList: [], //课程列表list
      tokenFlag: false, //判断是否登录
      isbuied: false, //判断是否购买
      currentCourse: {},
      currentModule: {},
      currentChapter: {},
      chapterList: [],
      list: [],
      videoInfo: '',
      player: '',
      dialogVisibleBofang: false,
      currentVid: '',
      currentVideoId: '',
      ts: '',
      sign: '',
      currentHeight: '',
      isShowTip: false,
    }
  },
  components: {
    BannerMycourse,
  },
  computed: {},
  created() {
    let token = ''
    if (!token) {
      this.tokenFlag = true
    } else {
      this.tokenFlag = false
      if (this.mycourseList.length) {
        this.isbuied = false
        this.getMySubjectGoods() //课程列表
      } else {
        this.isbuied = true
      }
    }
  },
  mounted() {
    console.log('this.$route')
    console.log(this.$route)
    let code = this.$route.query.code ? this.$route.query.code : ''
    if (sessionStorage.getItem('token')) {
      this.getMySubjectGoods(sessionStorage.getItem('token'))
    } else {
      if (this.$store.state.token) {
        this.getUserInfo()
      } else {
        this.getToken(code)
      }
    }
  },
  beforeDestory() {
    this.commitLearnTime()
  },
  beforeRouteLeave(to, from, next) {
    if (this.player) {
      this.commitLearnTime()
    }
    next()
  },
  methods: {
    getToken(code) {
      console.log('component-mycourse')
      if (!code) {
        return false
      }
      let params = {
        code: code,
        grant_type: 'wx',
        scope: 'read',
        type: 'web',
      }
      request
        .getWXToken(params)
        .then((res) => {
          console.log(res)
          this.$store.commit('setToken', res.data)
          this.getUserInfo()
        })
        .catch((err) => {
          console.error(err)
        })
      // request
      //   .pcLogin(params)
      //   .then((res) => {
      //     console.log('get token')
      //     console.log(res)
      //     sessionStorage.setItem('token', JSON.stringify(res.data.tokenKey))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    },
    getUserInfo() {
      request
        .getUserInfo()
        .then((res) => {
          console.log(res)
          if (!res.data.data.phone) {
            // this.$message('')
            alert('请到小程序绑定手机号')
            return
          }
          this.userInfo = res.data.data
          this.$store.commit('setUserInfo', res.data.data)
          this.getMyCourseGoodsList(res)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getMyCourseGoodsList(res) {
      // userId: a6803d2ff0cb79461c27b775699c2185
      // current: 1
      // size: 500
      // type: 2
      let params = {
        //  userId: a6803d2ff0cb79461c27b775699c2185
        userId: res.data.data.userId,
        current: 1,
        size: 500,
        type: 2,
      }
      request
        .getMyCourseGoodsList(params)
        .then((res) => {
          console.log(res)
          this.mycourseList = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getModuleParentRecord(course) {
      this.currentCourse = course
      request
        .getModuleParentRecord({
          goodsId: course.goodsId,
          parentId: 0,
          orderNo: course.orderNo,
        })
        .then((res) => {
          console.log(res)
          this.moduleList = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getCharpterRecord(moduleItem) {
      this.currentModule = moduleItem
      request
        .getCharpterRecord({
          goodsId: moduleItem.goodsId,
          orderNo: this.currentCourse.orderNo,
          parentId: moduleItem.chapterId,
          userId: this.userInfo.userId,
          finishType: moduleItem.finishType,
        })
        .then((res) => {
          console.log(res)
          this.chapterList = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getCharpters(chapter) {
      this.currentChapter = chapter
      request
        .getCharpters({
          orderNo: this.currentCourse.orderNo,
          productId: chapter.productId,
          parentId: chapter.chapterId,
          chapterName: chapter.chapterName,
          type: 2,
          goodsId: chapter.goodsId,
          userId: this.userInfo.userId,
          isRank: 0,
        })
        .then((res) => {
          console.log(res)
          this.list = res.data.data
          let video = res.data.data.find((e) => e.isRel === 3)
          if (!video) {
            // alert('该章节没有视频')
            this.isShowTip = true
            const timer = setTimeout(() => {
              this.isShowTip = false
              clearTimeout(timer)
            }, 3000)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    findCharpterVideo(item) {
      if (this.player) {
        this.commitLearnTime()
      }
      request
        .findCharpterVideo({
          goodsId: this.currentCourse.goodsId,
          charpterId: item.chapterId,
          parentId: item.parentId,
          userId: this.userInfo.userId,
        })
        .then((res) => {
          console.log(res)
          this.videoInfo = res.data.data[0]
          this.manualPlay(res.data.data[0])
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 手动播放
    manualPlay(val) {
      let that = this
      that.currentVid = val.vid
      that.currentVideoId = val.videoId
      if (that.player) {
        that.player.destroy()
      }
      that.getPlaysafetoken()
    },
    // 播放api
    loadPlayer() {
      let that = this
      this.currentHeight = window.screen.availHeight
      console.log(this.currentHeight)
      that.dialogVisibleBofang = true
      const polyvPlayer = window.polyvPlayer
      that.$nextTick(() => {
        if (that.$refs['player']) {
          that.player = polyvPlayer({
            wrap: '#player',
            width: '100%',
            height: '100%',
            vid: that.currentVid,
            playsafe: that.playsafe,
            ts: that.ts,
            sign: that.sign,
            // autoplay: true,
          })
          let timer = setTimeout(() => {
            // this.player.on('ready', () => {
            //   console.log('ready')
            // })
            if (this.currentChapter.seeTime) {
              this.seekVideo(this.currentChapter.seeTime)
            }
            this.player.on('s2j_onVideoPause', () => {
              console.log('s2j_onVideoPause')
              console.log('this.player.j2s_getCurrentTime()')
              console.log(this.player.j2s_getCurrentTime())
              console.log('this.player.j2s_getDuration()')
              console.log(this.player.j2s_getDuration())
            })
            this.player.on('s2j_onVideoSeek', (res) => {
              console.log('s2j_onVideoSeek')
              console.log(res)
            })
            // this.player.on('ratechange', () => {
            //   console.log('ratechange')
            // })
            this.player.on('s2j_onPlayOver', () => {
              console.log('s2j_onPlayOver')
              console.log('this.player.j2s_realPlayVideoTime()')
              console.log(this.player.j2s_realPlayVideoTime())
              this.commitLearnTime()
            })
            clearTimeout(timer)
          }, 300)
        }
        console.log('that.$refs.player')
        console.log(that.$refs.player)
      })
    },
    seekVideo(seek) {
      this.player.j2s_seekVideo(seek)
    },
    commitLearnTime() {
      // chapterName:
      // orderNo: 513371798365011968
      // subjectId: 84228c380f6259667f7a8ae592730f56
      // goodsId: 6a34561ca1d34fbf093cbc27aba598f0
      // chapterId: 7862e076989d6e1c423d77700406799d
      // parentId: 8c898f205a9806a53bf76f4ac89b09e3
      // seeTime: 98
      // allTime: 1029
      // sort: 7
      // realTime: 164
      // moduleId: fb7e6b843d2d3a29250a5810e5dbbe06
      request
        .commitLearnTime({
          chapterName: this.currentChapter.chapterName,
          orderNo: this.currentCourse.orderNo,
          subjectId: this.currentChapter.productId,
          goodsId: this.currentCourse.goodsId,
          chapterId: this.currentChapter.chapterId,
          parentId: this.currentChapter.parentId,
          seeTime: Math.floor(this.player.j2s_realPlayVideoTime() * 2),
          allTime: this.player.j2s_getDuration(),
          sort: this.currentChapter.sort,
          realTime: Math.floor(this.player.j2s_getCurrentTime()),
          moduleId: this.currentModule.chapterId,
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 加密播放token
    getPlaysafetoken() {
      let that = this
      let params = {
        videoId: this.currentVid,
        viewerId: 14934980359,
        iswxa: 0,
      }
      request
        .getPlaysafetoken(params)
        .then((res) => {
          that.playsafe = res.data.data
          let paramsVid = {
            vid: that.currentVid,
          }
          request.getPlaysafesign(paramsVid).then((res) => {
            that.ts = res.data.data.ptime
            that.sign = res.data.data.sign
            that.loadPlayer()
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCloseBofang(done) {
      let that = this
      if (that.player) {
        that.player.destroy()
      }
      done()
    },
    // getToken(code) {
    //   if (!code) {
    //     return false;
    //   }
    //   let params = {
    //     code: code,
    //   }
    //   request.pcLogin(params).then(res => {
    //     console.log("get token")
    //     console.log(res);
    //     sessionStorage.setItem('token', JSON.stringify(res.data.tokenKey));
    //     this.getMySubjectGoods(res.data.tokenKey);
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    /**
     * @desc 课程列表
     * @param {Object} token
     */
    getMySubjectGoods(token) {
      if (!token) {
        return false
      }
      let params = {
        token: token,
      }
      request
        .getMySubjectGoods(params)
        .then((res) => {
          console.log('get token')
          console.log(res)
          sessionStorage.setItem('mySubjectGoods', JSON.stringify(res.data))
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // //课程登录
    // pcLogin(){
    // 	let params={

    // 	}
    // 	request.pcLogin(params).then(res => {
    // 		console.log(res)
    // 		// if (res.data.status === 'ok') {
    // 		// 	this.mycourseList = res.data.result;
    // 		// }
    // 	}).catch(err => {
    // 		console.log(err)
    // 	})
    // },

    // 课程列表
    // getMySubjectGoods() {
    // let params={

    // }
    // request.getMySubjectGoods(params).then(res => {
    // 	console.log(res)
    // 	if (res.data.status === 'ok') {
    // 		this.mycourseList = res.data.result;
    // 	}
    // }).catch(err => {
    // 	console.log(err)
    // })
    // },
    // 去登陆
    showLogin: function () {
      this.$store.commit('setLogin', true)
    },
  },
}
</script>

<style lang="scss">
.btn {
  width: 100px;
  height: 40px;
  font-size: 16px;
}

.mycourse__content {
  padding: 0 10px;
  margin: 20px 0;

  // width: 45%;
  .course-name {
    padding: 0 10px;
    margin: 0 10px;
    // float: left;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 4px solid #21a854;

    .name {
      // width: 300px;
      flex: 1;
    }

    .btn {
      width: 100px;
      height: 40px;
      font-size: 16px;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.course-item {
  padding: 10px 0;
  margin-left: 40px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #21a854;

  .module-name {
    padding: 10px;
    flex: 1;
  }
}

.chapter-item {
  padding: 10px 0;
  margin-left: 80px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #21a854;

  .chapter-name {
    flex: 1;
  }
}

.item {
  display: flex;
  align-items: center;
  margin-left: 110px;
  margin-right: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #21a854;

  .name {
    flex: 1;
  }
}

.mycourse {
  &__warp {
    position: relative;
    margin: 0 auto;
    // padding: 80px 510px 40px 0;
    padding-top: 60px;
    padding-bottom: 30px;
    // max-width: 1140px;
    padding-left: 10%;
    padding-right: 10%;

    .user-img {
      margin: 10px;
      width: 50px;
      height: 70px;
      border: 1px solid #bbb;
    }
  }

  h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 15px;
  }

  &__nologin {
    text-align: center;

    img {
      width: 380px;
      height: 320px;
      display: block;
      margin: auto;
    }

    button {
      width: 100px;
      height: 40px;
      display: block;
      background: #21a854;
      color: #fff;
      border-radius: 4px;
      outline: none;
      border: none;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    span {
      display: block;
      color: #999;
      font-size: 14px;
      margin-bottom: 80px;
    }
  }

  &__nobuied {
    text-align: center;

    img {
      width: 380px;
      height: 320px;
      display: block;
      margin: auto;
    }

    span {
      display: block;
      color: #999;
      font-size: 14px;
      margin-bottom: 80px;
    }
  }
}
.main-video {
  display: flex;
  height: 600px;
  .video-list {
    width: 500px;
    overflow-y: auto;
    .title {
      background-color: #ddd;
      padding: 20px 0;
      padding-left: 20px;
      font-size: 18px;
    }
  }
  .video-container {
    flex: 1;
    .video-wrap {
      padding: 5px;
      .player {
        height: 600px;
        // min-width: 800px;
      }
    }
    .no-video {
      height: 600px;
      padding-top: 280px;
      margin-right: 20px;
      text-align: center;
      font-size: 30px;
      color: #999;
      box-shadow: 0 0 5px #999;
    }
  }
}
.toast {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
  &__con {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #eee;
    padding: 80px 100px;
    border-radius: 10px;
    font-size: 18px;
  }
}
</style>
