<template>
	<div class="mycourse">
		<div class="mycourseWarp"></div>
		<div class="container-of">
			
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BannerMycourse'
	}
</script>

<style>
	.mycourseWarp{
		width: 100%;
		height: 120px;
		background: #333;
	}
</style>
